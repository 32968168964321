export default function ({ store, $axios, app, redirect }, inject) {
    $axios.defaults.baseURL = "/zapi"

    inject('addAuthHeaders', function() {
        $axios.setHeader('x-app-ver', 1.001);
        $axios.setHeader(
            'Authorization',
            'Bearer ' + app.$tokens.accessTokenValue()
        )
    })
  }
