
const defaultState = {
    orders: [],
    paginatedDetails : {totalCount : 0, pageNum : 1, pageSize : 10},
    orderLoadState : 0,
    orderDetail: {}
}

export const state = () => ({...defaultState})

export const mutations = {
    setOrders(state, formattedResp) {
        let orders = formattedResp.orderDetailsArr
        state.orders = [...state.orders, ...orders]
    },
    setOrderDetail(state, order) {
        state.orderDetail = order
    },
    addOrders(state, pageDetails) {
        //state.orders
    },
    setOrderLoadState(state, orderLoadState) {
        state.orderLoadState = orderLoadState
    },
    setPaginatedDetails(state, paginatedDetails) {
        state.paginatedDetails = {...state.paginatedDetails, ...paginatedDetails}
    },
    resetState(state) {

            state.orders =  defaultState.orders
            state.paginatedDetails =  defaultState.paginatedDetails
            state.orderLoadState =  defaultState.orderLoadState
            state.orderDetail =  defaultState.orderDetail
    }
}

export const actions = {
    fetchOrders({commit}, params={}) {
        debugger
        this.$addAuthHeaders()
        return this.$axios.get("/users/orders", {params})
        .then( response => {
            let formattedResp = this.$parseSuccess(response)
            commit('setOrders', formattedResp)
            commit('setPaginatedDetails', {
                totalCount : formattedResp.paginatedResp.totalCount,
                ...params
            })
        })
        .catch( error => {
            this.$toast.error(this.$parseErrorMsg(error))
        })
    }
}

export const getters = {
    getOrders(state) {
        return state.orders
    },
    getOrderLoadState(state) {
        return state.orderLoadState
    },
    getOrderDetail(state) {
        return state.orderDetail
    },
    getPaginatedDetails(state) {
        return state.paginatedDetails
    },
    getOrdersAtPage(state) {
        return pageNum => {
            return pageNum;
        }
    }
}