<template>
    <span>
        {{ formattedDate }}
    </span>
</template>

<script>
export default {
    props:{ date: { default: '' }, outputFormat:{ default: 'MMMM D, HH:mm A' }, inputFormat: { default: 'YYYY-MM-DD' } },
    computed:{
        formattedDate() {
            return  this.date ?  moment(this.date).format( this.outputFormat ) : ''
        }
    }
}
</script>

<style>

</style>
