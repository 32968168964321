class Tokens {

    setToken (token) {
      //console.log(token, "setToken");
      this.setAccessToken(token)
      this.serRefreshToken(token)
    }

    setAccessToken(token) {
        localStorage.setItem( this.accessTokenKey , token.access_token)
        localStorage.setItem(this.accessTokenExpiryKey , moment().utc().add(token.access_token_expiry, "milliseconds").valueOf())
    }
    serRefreshToken(token) {
      console.log("refresh token ", token.refresh_token);
        localStorage.setItem(this.refreshTokenKey , token.refresh_token)
        localStorage.setItem(this.refreshTokenExpiryKey,  moment().utc().add(token.refresh_token_expiry, "milliseconds").valueOf())
    }

    get accessTokenKey() {
        return "access_token"
    }
    get accessTokenExpiryKey() {
        return "access_token_expiry"
    }
    get refreshTokenKey() {
        return "refresh_token"
    }
    get refreshTokenExpiryKey() {
        return "refresh_token_expiry"
    }
    accessToken() {
      //console.log(token, expiration, "getToken")
      if( !this.accessTokenValue() || !this.accessTokenExpiryValue() ) {
        return null
      }
      // whether token expired
      if(this.isAccessTokenExpired())
        this.destroyAccessToken()

      return this.accessTokenValue();
    }

    accessTokenValue() {
        return localStorage.getItem(this.accessTokenKey)
    }

    accessTokenExpiryValue() {
      return moment(parseInt(localStorage.getItem(this.accessTokenExpiryKey))).utc();
    }

    refreshTokenValue() {
        return localStorage.getItem(this.refreshTokenKey)
    }
    refreshTokenExpiryValue() {
        return moment(parseInt(localStorage.getItem(this.refreshTokenExpiryKey))).utc();
    }

    isAccessTokenExpired() {
      return this.isPastTime(this.accessTokenExpiryValue());
    }

    isRefreshTokenExpired() {
        return this.isPastTime(this.refreshTokenExpiryValue());
      }

    /* if time = null/undefined or time comes before now -> token expired */
    isPastTime(time) {
      return moment(time).isValid() ? moment().utc() >= moment(time).utc() : true
    }

    destroyAccessToken () {
      localStorage.removeItem(this.accessTokenKey );
      localStorage.removeItem(this.accessTokenExpiryKey);
    }

    destroyRefreshToken () {
        localStorage.removeItem(this.refreshTokenKey );
        localStorage.removeItem(this.refreshTokenExpiryKey);
    }

    clear() {
      this.destroyAccessToken();
      this.destroyRefreshToken()
    }

    prepareAccessTokenHeader() {
      return {
        'x-app-ver' : 1,
        'Authorization' : 'Bearer ' + this.accessTokenValue()
      }
    }

    prepareRefreshTokenHeader() {
      return {
        'x-app-ver' : 1,
        'Authorization' : 'Bearer ' + this.refreshTokenValue()
      }
    }
  }

export default ({ store, app }, inject) => {
  let tokens = new Tokens
  store.dispatch('initilaizeTokens', tokens)
  inject('tokens', tokens)
}