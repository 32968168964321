
export const state = () => ({
    phone: '',
    loginReference: '',
    isUserRegistering: false,
    user: {}
})

export const mutations = {
    setPhone(state, phone) {
        state.phone = phone
    },
    setRef(state, ref) {
        state.loginReference = ref
    },
    toggleIsUserRegisteringFlag(state) {
        state.isUserRegistering = !state.isUserRegistering
    },
    setUer(state, user) {
        state.user = user
    },
    setUser(state, user) {
        state.user = user
    }

}

export const getters = {
    getPhone(state) {
        return state.phone
    },
    getLoginRef(state) {
        return state.loginReference
    },
    isUserRegistering(state) {
        return state.isUserRegistering
    },
    getUser(state) {
        return state.user
    }
}
