
export default function ({ store, $axios, app, redirect }, inject) {

    inject('authHeaders', () => {
        return {
            headers: {
                'x-app-ver' : 1
            },
            auth: {
                username: "webZipydo",
                password: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
            }
        }
    })

    inject('isUserLoggedIn', () => {
        return store.state.authUser
    })
  }