import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";

export const state = () => ({
    kiosks:{},
    selectedKiosk : null,
    openKioskId : null
})

export const mutations = {
    setKiosks(state, kiosks) {
        state.kiosks = kiosks
    },
    setSelectedKiosk(state, selectedKiosk) {
      state.selectedKiosk = selectedKiosk
    },
    setOpenKioskId(state, kioskId) {
        state.openKioskId = kioskId
    }
}

export const getters = {
    getKiosks(state) {
        return state.kiosks
    },
    getSelectedKiosk(state) {
        return state.selectedKiosk
    },
    getOpenKioskId(state) {
        return state.openKioskId
    }
}

export const actions = {
    setOpenKioskId({commit}, kioskId) {
        commit('setOpenKioskId', kioskId)
    },
    openKiosk({commit}, kioskId) {
        this.$toast.show(`Opening Kisok - id : ${kioskId}`)
        this.$axios.post("/users/open", { kioskId, proximityAuth: { userPermission: true  }  })
                .then( response => {
                    const data = this.$parseSuccess(response)
                    this.showKioskOpenedSuccess(data)
                })
                .catch( error => {
                    if (error && error.response && error.response.data
                        && error.response.data.error 
                        && 3058 == error.response.data.error.errCode) {
                        VueSimpleAlert.confirm('', 'Minimum Balance', 'warning', {
                            html : '<body><p><pre style="white-space: pre-wrap;overflow:auto;font-family: inter">' + error.response.data.error.errMsg + '</pre></p></body>',
                            confirmButtonText : 'Proceed', reverseButtons : true}
                            ).then(r => {
                                if (r == true) {
                                    this.$router.push({path:'/payment/addbalance/', query : {minBalance : true}}  )
                                } else {
                                    this.$toast.error('Cancelled')
                                }
                            }).catch(e => {
                                this.$toast.error('Cancelled')
                            })
                        } else {
                            this.$toast.error(this.$parseErrorMsg(error))
                        }
                })
    },
    showKioskOpenedSuccess(data) {
        let msg = ""
        if( data.kioskDisplayName)
            msg = data.kioskDisplayName
        if( data.openOtp)
            msg += ` (OTP - ${data.openOtp})`
        this.$toast.info( msg )
    }
}
