
const defaultState = {
    payments: [],
    paginatedDetails : {totalCount : 0, pageNum : 1, pageSize : 10},
    paymentLoadState : 0,
    paymentDetail: {}
}

export const state = () => ({...defaultState})

export const mutations = {
    setPayments(state, formattedResp) {
        let payments = formattedResp.txnsArr
        state.payments = [...state.payments, ...payments]
    },
    setPaymentDetail(state, payment) {
        state.paymentDetail = payment
    },
    addPayments(state, pageDetails) {
        //state.orders
    },
    setPaymentLoadState(state, paymentLoadState) {
        state.paymentLoadState = paymentLoadState
    },
    setPaginatedDetails(state, paginatedDetails) {
        state.paginatedDetails = {...state.paginatedDetails, ...paginatedDetails}
    },
    resetState(state) {

            state.payments =  defaultState.payments
            state.paginatedDetails =  defaultState.paginatedDetails
            state.paymentLoadState =  defaultState.paymentLoadState
            state.paymentDetail =  defaultState.paymentDetail
    }
}

export const actions = {
    fetchPayments({commit}, params={}) {
        debugger
        this.$addAuthHeaders()
        return this.$axios.get("/users/balanceHistory", {params})
        .then( response => {
            let formattedResp = this.$parseSuccess(response)
            commit('setPayments', formattedResp)
            commit('setPaginatedDetails', {
                totalCount : formattedResp.paginatedResp.totalCount,
                ...params
            })
        })
        .catch( error => {
            this.$toast.error(this.$parseErrorMsg(error))
        })
    }
}

export const getters = {
    getPayments(state) {
        return state.payments
    },
    getPaymentLoadState(state) {
        return state.paymentLoadState
    },
    getPaymentDetail(state) {
        return state.paymentDetail
    },
    getPaginatedDetails(state) {
        return state.paginatedDetails
    },
    getPaymentsAtPage(state) {
        return pageNum => {
            return pageNum;
        }
    }
}