import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _113b3820 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _505e80ab = () => interopDefault(import('../pages/kiosks/index.vue' /* webpackChunkName: "pages/kiosks/index" */))
const _02f8cd58 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _75863526 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _5d1ef0fb = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _cd234e44 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _64b44009 = () => interopDefault(import('../pages/auth/input-text.vue' /* webpackChunkName: "pages/auth/input-text" */))
const _737d9963 = () => interopDefault(import('../pages/auth/layout.vue' /* webpackChunkName: "pages/auth/layout" */))
const _396e1cc0 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _309aef42 = () => interopDefault(import('../pages/auth/otp.vue' /* webpackChunkName: "pages/auth/otp" */))
const _199677c8 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _cea2469a = () => interopDefault(import('../pages/auth/terms-conditions-text.vue' /* webpackChunkName: "pages/auth/terms-conditions-text" */))
const _4c4e2f25 = () => interopDefault(import('../pages/home/index copy.vue' /* webpackChunkName: "pages/home/index copy" */))
const _7915c0a3 = () => interopDefault(import('../pages/home/offers-home.vue' /* webpackChunkName: "pages/home/offers-home" */))
const _cec966c4 = () => interopDefault(import('../pages/home/pending-payment.vue' /* webpackChunkName: "pages/home/pending-payment" */))
const _3209477e = () => interopDefault(import('../pages/home/recent-order.vue' /* webpackChunkName: "pages/home/recent-order" */))
const _66431fff = () => interopDefault(import('../pages/home/userbalance.vue' /* webpackChunkName: "pages/home/userbalance" */))
const _a2e953d4 = () => interopDefault(import('../pages/kiosks/menu.vue' /* webpackChunkName: "pages/kiosks/menu" */))
const _ca2c2fd8 = () => interopDefault(import('../pages/kiosks/menu-item-detail.vue' /* webpackChunkName: "pages/kiosks/menu-item-detail" */))
const _1718258a = () => interopDefault(import('../pages/offers/detail.vue' /* webpackChunkName: "pages/offers/detail" */))
const _58d901a6 = () => interopDefault(import('../pages/orders/detail.vue' /* webpackChunkName: "pages/orders/detail" */))
const _8044754c = () => interopDefault(import('../pages/orders/payment.vue' /* webpackChunkName: "pages/orders/payment" */))
const _3ebe8042 = () => interopDefault(import('../pages/payment/addbalance.vue' /* webpackChunkName: "pages/payment/addbalance" */))
const _1b5f92a4 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _7e397878 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/home",
    component: _113b3820,
    name: "home"
  }, {
    path: "/kiosks",
    component: _505e80ab,
    name: "kiosks"
  }, {
    path: "/offers",
    component: _02f8cd58,
    name: "offers"
  }, {
    path: "/orders",
    component: _75863526,
    name: "orders"
  }, {
    path: "/payment",
    component: _5d1ef0fb,
    name: "payment"
  }, {
    path: "/profile",
    component: _cd234e44,
    name: "profile"
  }, {
    path: "/auth/input-text",
    component: _64b44009,
    name: "auth-input-text"
  }, {
    path: "/auth/layout",
    component: _737d9963,
    name: "auth-layout"
  }, {
    path: "/auth/login",
    component: _396e1cc0,
    name: "auth-login"
  }, {
    path: "/auth/otp",
    component: _309aef42,
    name: "auth-otp"
  }, {
    path: "/auth/register",
    component: _199677c8,
    name: "auth-register"
  }, {
    path: "/auth/terms-conditions-text",
    component: _cea2469a,
    name: "auth-terms-conditions-text"
  }, {
    path: "/home/index copy",
    component: _4c4e2f25,
    name: "home-index copy"
  }, {
    path: "/home/offers-home",
    component: _7915c0a3,
    name: "home-offers-home"
  }, {
    path: "/home/pending-payment",
    component: _cec966c4,
    name: "home-pending-payment"
  }, {
    path: "/home/recent-order",
    component: _3209477e,
    name: "home-recent-order"
  }, {
    path: "/home/userbalance",
    component: _66431fff,
    name: "home-userbalance"
  }, {
    path: "/kiosks/menu",
    component: _a2e953d4,
    name: "kiosks-menu"
  }, {
    path: "/kiosks/menu-item-detail",
    component: _ca2c2fd8,
    name: "kiosks-menu-item-detail"
  }, {
    path: "/offers/detail",
    component: _1718258a,
    name: "offers-detail"
  }, {
    path: "/orders/detail",
    component: _58d901a6,
    name: "orders-detail"
  }, {
    path: "/orders/payment",
    component: _8044754c,
    name: "orders-payment"
  }, {
    path: "/payment/addbalance",
    component: _3ebe8042,
    name: "payment-addbalance"
  }, {
    path: "/profile/edit",
    component: _1b5f92a4,
    name: "profile-edit"
  }, {
    path: "/",
    component: _7e397878,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
