import Vue from 'vue'
import DateLabel from '~/components/date-label.vue'

window.moment = require('moment');

Vue.component('date-label', DateLabel)

Vue.prototype.$formatValue = function(val) {
    if (!val) {
        val = 0;
    }
    return parseFloat(val).toFixed(2);
}