import Vue from 'vue'

export default ({ app }, inject) => {
    inject('parseSuccess', response => {
        if( response && response.data)
            return response.data.resp
        return {}
    })

    inject('parseErrorMsg' , error => {
        if( error && error.response && error.response.data
                && error.response.data.error && error.response.data.error.errMsg  )
            return error.response.data.error.errMsg
        return "Unknown error"
    })
}
