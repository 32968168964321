export default async function ({redirect}) {
    let agent = navigator.userAgent.toLowerCase()
    //console.log('validate middleware ', agent)
    if (agent.indexOf('android') >= 0) {
        redirect('https://play.google.com/store/apps/details?id=com.zipydo.shop.food.office')
    } else if (agent.indexOf('linux') >= 0 
            || agent.indexOf('windows') >= 0 
            ) {
	           redirect('https://www.zipydo.com')
    }
}
