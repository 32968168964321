
export default async function ({ store, $axios,  app, route, redirect }) {
  let routePath = route.path
  let routeQuery = route.query

  let shouldRedirectToLogin = false
  let shouldFetchUser = false

  if (routePath == '/')
    return redirect('/home')

  // If not login, register,otp or home - need to fetch user
  const routeList = [ /\/auth\/login|register|otp/, /\/home/]
  if (routeList.findIndex(pathRegex => pathRegex.test(route.path)) < 0) {
    shouldFetchUser = true
  }
  // If home and kiosk open - then need to fetch user
  if (routeQuery.kioskid || routeQuery.kioskId && /\/home/.test(routePath)) {
    store.dispatch('kiosk/setOpenKioskId', route.query.kioskid || route.query.kioskId)
    shouldFetchUser = true
  }

  if (!app.$tokens.isAccessTokenExpired() || !app.$tokens.isRefreshTokenExpired()) {
    shouldFetchUser = true
    shouldRedirectToLogin = false
  }

  // For fetching user
  if (shouldFetchUser) {
    // If access token is expired but still has valid refresh token, just fetch
    // new tokens
    if (app.$tokens.isAccessTokenExpired() && !app.$tokens.isRefreshTokenExpired()) {
      await store.dispatch('refetchAccessToken')
    } else if (app.$tokens.isRefreshTokenExpired()) {
      /* User fetch, but both tokens expired - only way is to redirect to login */
      shouldRedirectToLogin = true
    }
  }
  
  // Order of the below if's matter

  if (shouldRedirectToLogin) {
    return redirect(`/auth/login?redirect=${routePath}`)
  }
  // User is required, but its not yet fetched.. so fetch before completing the mounting
  if (shouldFetchUser && !store.state.authUser) {
    await store.dispatch('fetchUser')
  }
  
  // if (/\/home/.test(routePath) && Object.keys(route.query).length) {
  //   store.dispatch('kiosk/setOpenKioskId', route.query.kioskid || route.query.kioskId)
  // }

  // if( app.$tokens.isRefreshTokenExpired() || app.$tokens.isAccessTokenExpired() ) {
  //   const routeList = [ /\/auth\/login|register|otp/, /\/home/]
  //   if (/\/home/.test(routePath) && Object.keys(route.query).length) {
  //     return redirect('/auth/login')
  //   }
  //   else if( routeList.findIndex(pathRegex => pathRegex.test(route.path)) < 0 )
  //       return redirect('/auth/login')
    
  // }
  // // else if (app.$tokens.isAccessTokenExpired() ) {
  // //   store.dispatch('refetchAccessToken')
  // // }
  // else if( !store.state.authUser ) {
  //   // store.dispatch('fetchUser')
  // } else {
  //   //redirect('/home')
  // }

  //return true
}
