import Vue from 'vue'

import {Alert, Confirm, Toast, Loading} from 'wc-messagebox'

import D from 'wc-messagebox'

import 'wc-messagebox/style.css'
Vue.use(D)
Vue.use(Alert)
Vue.use(Confirm)
Vue.use(Loading)
