export const state = () => ({
    offers: {},
    offerDetail: {}
})

export const mutations = {
    setOffers(state, offers) {
        state.offers = offers
    },
    setOfferDetail(state, offerDetail) {
        state.offerDetail = offerDetail
    }
}

export const getters ={
    getOffers(state) {
        return state.offers
    },
    getOfferDetail(state) {
        return state.offerDetail
    }
}