import Vue from 'vue'
import axios from 'axios'

export const state = () => {
  return {
    home: null,
    authUser: null,
    $tokens: '',
    $axios: '',
  }
}
export const mutations = {
  setHome(state, home) {
    state.home = home
  },
  setAuthUser (state, auth) {
    state.authUser = auth
  },
  setTokens(state, tokens) {
    state.$tokens = tokens
  },
  setAxios(state, axios) {
    state.$axios = axios
  },
  initializeTokens(state, response) {
    state.$tokens.setToken(response)
    state.authUser = response.user
  },
  clearAuthData(state) {
    state.authUser = null
    state.$tokens.clear()
  },
  resetAccessToken(state, token) {
    state.$tokens.setAccessToken(token)
  },
  updateProfilePicUrl(state, picUrl) {
    if( !state.authUser.picUrl)
      state.authUser.picUrl = {}

      state.authUser.picUrl.path = picUrl
  }
}
export const actions = {
  fetchHome({commit, state}) {
    var h;
    if (undefined !==  state.authUser && null !== state.authUser) {
      h = { headers:  state.$tokens.prepareAccessTokenHeader()  }
    } else {
      h = this.$authHeaders()
    }
    return axios.get("/users/home",  h)
    .then( response => {
        commit('setHome', response.data.resp)
    })
  },
  initilaizeTokens({commit}, tokens) {
    commit('setTokens', tokens)
  },
  initilaizeAxios({commit}, axios) {
    commit('setAxios', axios)
  },
  refetchAccessToken({commit, state}) {
    return axios.post("/users/refreshToken", { refresh_token: state.$tokens.refreshTokenValue() } , { headers:  state.$tokens.prepareAccessTokenHeader()  })
             .then( response => {
               try {
                 Native.updateTokens(response.data.resp.access_token, response.data.resp.access_token_expiry);
                } catch (e) {
                  console.log(e);
                }
                 commit('resetAccessToken', response.data.resp)
             }).catch (error => {
               commit('clearAuthData')
               this.$router.push({path:'/auth/login'})
             })
  },
  fetchUser({commit, state}) {
    return axios.get("/users", { headers:  state.$tokens.prepareAccessTokenHeader()  })
            .then( response => {
                commit('setAuthUser',  response.data.resp)
                return response
            })

  }
}

export const getters = {
   getTokens(state) {
     return state.$tokens
   },
   getAuthUser(state) {
     return state.authUser
   },
   getOrders(state) {
     if (state.home && state.home.pastOrders) {
       return state.home.pastOrders.orderDetailsArr
     } else {
       return []
     }
   },
   getUserBalance(state) {
     if (state.home && undefined != state.home.userBalance) {
        return state.home.userBalance
     } else {
       return null
     }
   },
   getOffers(state) {
     if (state.home && undefined != state.home.campaigns && 0 < state.home.campaigns.length) {
       let offers = []
       state.home.campaigns.map((c) => {
         if (c.type == 'offer') {
           offers.push(c)
          }
        })
        return offers
     } else {
        return null
     }
   }
}
